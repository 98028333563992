import {
  AndOrOperator,
  ConditionalOperators,
  EMPTY_FIELD_VALUE,
} from 'app/modules/detectionModels/constants';
import {
  Condition,
  DropdownBuilderFormValues,
} from 'app/modules/detectionModels/components/DropdownBuilderForm/models';
import { FieldValueOperand } from 'app/modules/rulesAdvanced/lib/SymbolTable';

export const EMPTY_FIELD_VALUE_OPERAND: FieldValueOperand = {
  operandType: 'text',
  value: EMPTY_FIELD_VALUE,
};

export const EMPTY_DROPDOWN_BUILDER_CONDITION: Condition = {
  type: 'rule_condition_composite',
  operator: ConditionalOperators.EQ,
  leftOperand: EMPTY_FIELD_VALUE_OPERAND,
  rightOperand: null,
  betweenOperand: null,
};

export const DEFAULT_DROPDOWN_BUILDER_FORM_VALUES: DropdownBuilderFormValues =
  Object.freeze({
    facts: [],
    condition: {
      type: 'rule_condition_composite',
      operator: AndOrOperator.AND,
      conditions: [EMPTY_DROPDOWN_BUILDER_CONDITION],
    },
  });
