import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { RtmRuleBuilderFormValues } from 'app/modules/detectionModels/components/RtmRuleBuilderForm/models';
import { PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_DROPDOWN_BUILDER_FORM_VALUES } from 'app/modules/detectionModels/components/DropdownBuilderForm/constants';

const RTM_RULE_BUILDER_FORM_VALUES = 'RTM_RULE_BUILDER_FORM_VALUES';
interface RtmRuleBuilderFormValuesState {
  isReadonly: boolean;
  matchlistEnabled: boolean;
  formValues: RtmRuleBuilderFormValues;
}

const initialState: RtmRuleBuilderFormValuesState = {
  isReadonly: false,
  matchlistEnabled: true,
  formValues: {
    eventSubTypes: [],
    dropdownBuilderFormValues: DEFAULT_DROPDOWN_BUILDER_FORM_VALUES,
    objectToFlag: 'TXN_EVENT',
  },
};

const rtmRuleBuilderFormValuesSlice = u21CreateSlice({
  name: RTM_RULE_BUILDER_FORM_VALUES,
  initialState,
  reducers: {
    resetRtmFormValues: (draft) => {
      Object.assign(draft, initialState);
    },
    setIsReadonly: (draft, { payload }: PayloadAction<boolean>) => {
      draft.isReadonly = payload;
    },
    setCurrentFormValues: (
      draft,
      { payload }: PayloadAction<RtmRuleBuilderFormValues>,
    ) => {
      draft.formValues = payload;
    },
  },
});

export const RTM_RULE_BUILDER_FORM_VALUES_SLICE_NAME =
  rtmRuleBuilderFormValuesSlice.name;
export const { resetRtmFormValues, setCurrentFormValues } =
  rtmRuleBuilderFormValuesSlice.actions;
export default rtmRuleBuilderFormValuesSlice.reducer;
